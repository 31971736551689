
import { Component, Vue } from "vue-property-decorator";
import { Foundation } from "@/utils/Foundation";
import API_MerchantList from "@/utils/API/merchant/MerchantList";
import Api_videoApi from "@/utils/API/videovip/videoApi";

@Component({
    name: "auditingRefund",
})
export default class extends Vue {
    title: string = "退款基础信息";
    form: any = {};
    isLoading: boolean = false;
    rules: any = {
        agree: [
            { required: true, message: "请选择审核状态：", trigger: "blur" },
        ],
        operateRemark: [{ required: true, message: "请输入备注", trigger: "blur" }],
        confirmFile: [
            { required: true, message: "请上传退款回执单", trigger: "blur" },
        ],
    };

    show(data: any = {}) {
        (this.$refs.auditingRefund as any).show();
        this.$nextTick(() => {
            this.form = Foundation.deepClone(data);
            this.form.amount = Foundation.toMoney(data.amount);
            (this.$refs.addForm as any).clearValidate();
        });
    }

    hide() {
        (this.$refs.auditingRefund as any).hide();
    }

    /*表单提交*/
    async submitForm() {
        let form = Foundation.resetParams(this.form);

        (this.$refs.addForm as any).validate(async (valid: any) => {
            if (valid) {
                if (this.isLoading) return;
                this.isLoading = true;
                try {
                    let data: any = {
                        fundId: form.id,
                        agree: form.agree,
                        remark: form.operateRemark,
                        confirmFile: form.confirmFile,
                    };
                    await Api_videoApi.refundAgree(form.merchantId, data);
                    this.isLoading = false;
                    this.$message.success("操作成功");
                    this.hide();
                    this.$emit("submitForm", form);
                } catch (e) {
                    this.isLoading = false;
                }
            } else {
                return false;
            }
        });
    }

    /*图片上传*/
    uploadImg() {
        (this.$refs.imgChoice as any).show();
    }

    /*选择图片*/
    choiceImg(img: any) {
        this.$set(this.form, "confirmFile", img[0].ossUrl);
    }
}
