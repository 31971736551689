
import { Component, Vue } from "vue-property-decorator";
import { _refundrequest } from "@/utils/pageConfig/videovips/_refundrequest";
import { Foundation } from "@/utils/Foundation";
import emitRefund from "@/views/videovips/components/emitRefund.vue";
import auditingRefund from "@/views/videovips/components/auditingRefund.vue";
import Api_videoApi from "@/utils/API/videovip/videoApi";
import { capitalFlow, scenarioText } from "@/utils/pageConfig/videovips/common";

@Component({
    components: { emitRefund, auditingRefund },
})
export default class FinanceManage extends Vue {
    /*表格相关*/
    pageConfig: any = _refundrequest;
    tableData: any = [];
    total: number = 0;
    params: any = {
        current: 1,
        size: 20,
        scenes: "CASH_OUT",
        types: "FREEZE",
    };
    exportForm: any = {
        type: {
            tag: "ADMIN_FUND_MERCHANT_EXPORT",
            url: "/scheduler/api/v1/sch/export/exec/fund/merchant",
        },
        fileName: "财务总表",
    };

    mounted() {
        this.getAllMerchant();
    }

    async getAllMerchant() {
        let options: any = [];
        let res: any = await Api_videoApi.getOpenresource();
        res.map((e: any) => {
            options.push({ label: e.coName, value: e.id });
        });

        this.pageConfig.search[0].options = options;
    }

    /*获取表格数据*/
    async getTableData() {
        let res: any = await Api_videoApi.fundrecord(this.params);
        this.tableData =
            (res.records &&
                res.records.length > 0 &&
                res.records.map((e: any) => {
                    let c: any = JSON.parse(e.refundInfo);
                    e._recordTime = Foundation.dateFormat(e.recordTime);
                    e.reason = c.reason;
                    e.accountName = c.accountName;
                    e.accountNo = c.accountNo;
                    e.bankName = c.bankName;
                    e.refundFile = c.refundFile;
                    e._amount = Foundation.toYUAN(e.amount);
                    if (e.refundAgree) {
                        e._refundAgree = "审核通过";
                    } else if (e.refundAgree == false) {
                        e._refundAgree = "审核失败";
                    } else {
                        e._refundAgree = "待审核";
                    }
                    e._unfreeze = e.unfreeze ? "已退款" : "冻结中";
                    return e;
                })) ||
            [];
        this.total = res.total;
    }

    /*底部分页切换*/
    currentChange(pageNumber: number) {
        this.params.current = pageNumber;
        this.getTableData();
    }

    /*底部分页条数切换*/
    sizeChange(sizeNumber: number) {
        this.params.size = sizeNumber;
        this.getTableData();
    }

    /*查询*/
    handleSearch() {
        let query: any = Foundation.resetParams(
            (this.$refs.searchForm as any).form,
            true
        );
        query = Foundation.resetDate(query);
        if (!query.merchantId) {
            this.$message.error("请先选择商户！");
            return;
        }
        this.params = {
            current: 1,
            size: 20,
            scenes: "CASH_OUT",
            types: "FREEZE",
            ...query,
        };
        this.getTableData();
    }

    /*编辑*/
    handleEmit(data: any) {
        (this.$refs.emitRefund as any).show(data);
    }

    /*审核*/
    handleauditing(data: any) {
        (this.$refs.auditingRefund as any).show(data);
    }

    /*查看详情*/
    handlDetails(data: any) {
        (this.$refs.auditingRefund as any).show(data);
    }

    /*导出*/
    handleExport() {
        (this.$refs.exports as any).show(this.exportForm);
    }
}
