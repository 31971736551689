import Request from "../Request";

export default class MerchantList {
    public static baseUrl = '/core/api/v1/admin/b/merchant';

    public static getMerchantList(params: any) {
        return Request({
            url: `${this.baseUrl}/page`,
            method: "get",
            params
        });
    }

    public static addMerchant(data: any) {
        return Request({
            url: `${this.baseUrl}/create`,
            method: "post",
            data
        });
    }

    public static updateMerchant(data: any) {
        return Request({
            url: `${this.baseUrl}/update`,
            method: "post",
            data
        });
    }

    public static getAllMerchant() {
        return Request({
            url: `${this.baseUrl}/all`,
            method: "get",
            loading: false
        });
    }

    public static recharge(data: any) {
        return Request({
            url: `${this.baseUrl}/fund/recharge`,
            method: "post",
            data
        });
    }

    public static refund(data: any) {
        return Request({
            url: `${this.baseUrl}/fund/refund`,
            method: "post",
            data
        });
    }

    public static createMallMerchant(params: any) {
        return Request({
            url: `${this.baseUrl}/mall/merchant/create`,
            method: "get",
            params
        });
    }

    public static getMerchantAppkeyList(params: any) {
        return Request({
            url: `${this.baseUrl}/appkey/page`,
            method: "get",
            params
        });
    }


    public static updateSecret(params: any) {
        return Request({
            url: `${this.baseUrl}/appkey/updateSecret`,
            method: "get",
            params
        });
    }
}
