import { GoodsTypeText } from "@/utils/pageConfig/videovips/videoResources";

export const _refundrequest = {
    //筛选
    search: [
        {
            type: 'el-select',
            label: '商户：',
            value: 'merchantId',
            props: {
                filterable: true,
                placeholder: '请选择商户',
                clearable: true
            },
            options: []
        },
        {
            type: 'el-date-picker',
            label: '申请时间：',
            value: 'date',
            props: {
                type: 'daterange',
                'range-separator': '至',
                'start-placeholder': '开始日期',
                'end-placeholder': '结束日期',
                'default-time': ['00:00:00', '23:59:59'],
                'value-format': "timestamp"
            }
        },
    ],
    //列表
    table: [
        {
            prop: 'orderId',
            label: '申请流水',
        },
        {
            prop: '_amount',
            label: '退款金额'
        },
        {
            prop: 'reason',
            label: '退回理由',
            supField: "refundInfo",
        },
        {
            prop: 'accountName',
            label: '收款账户名称',
            supField: "refundInfo",
        },
        {
            prop: 'accountNo',
            label: '收款账户号',
            supField: "refundInfo",
        },
        {
            prop: 'bankName',
            label: '开户行',
            supField: "refundInfo",
        },
        {
            prop: '_refundAgree',
            label: '审核状态',
        },
        {
            prop: '_recordTime',
            label: '申请时间',
        },
        {
            prop: 'operateRemark',
            label: '备注',
        },
        {
            label: '操作',
            self: {
                body: 'operation'
            }
        }
    ],
};
