import Request from "../Request";

export default class VideoApi {

    //获取所有的资源方资源
    public static getAllThirdResource(params: any) {
        return Request({
            url: `/core/api/v1/admin/b/resource/getAllThirdResource`,
            method: "get",
            params
        });
    }

    /* 创建视频资源 */
    public static addvideo(data: any) {
        return Request({
            url: `/core/api/v1/admin/b/resource/create`,
            method: "post",
            data
        });
    }

    //资源分页查询e
    public static resourceQuery(params: any) {
        return Request({
            url: `/core/api/v1/admin/b/resource/query`,
            method: "get",
            params
        });
    }

    //资源 上架/下架
    public static listing(params: any) {
        return Request({
            url: `/core/api/v1/admin/b/resource/listing`,
            method: "get",
            params
        });
    }

    //资源设置 异常/正常
    public static abnormal(params: any) {
        return Request({
            url: `/core/api/v1/admin/b/resource/abnormal`,
            method: "get",
            params
        });
    }

    //自动更新采购价格
    public static purchaseprice(params: any) {
        return Request({
            url: `/core/api/v1/admin/b/resource/update/purchase/price`,
            method: "get",
            params
        });
    }

    //资金流水
    public static fundrecord(params: any) {
        return Request({
            url: `/core/api/v1/admin/b/resource/fund/record`,
            method: "get",
            params
        });
    }

    //创建奖品资源采购平台
    public static platformGenerate(merchantId: number) {
        return Request({
            url: `/core/api/v1/admin/b/resource/platform/generate/${merchantId}`,
            method: "get",
            params: {}
        });
    }

    //资金流水
    public static fundOverview(params: any) {
        return Request({
            url: `/core/api/v1/admin/b/resource/fund/overview`,
            method: "get",
            params
        });
    }

    /* 账户资金--充值 */
    public static fundRecharge(data: any) {
        return Request({
            url: `/core/api/v1/admin/b/resource/fund/recharge`,
            method: "post",
            data
        });
    }

    //扣款明细
    public static resourcePage(params: any) {
        return Request({
            url: `/core/api/v1/admin/b/resource/resource/record/page/${params.merchantId}`,
            method: "get",
            params,
        });
    }

    /* 账户资金--冲账 */
    public static advanceReverse(data: any) {
        return Request({
            url: `/core/api/v1/admin/b/resource/fund/advance/reverse`,
            method: "post",
            data
        });
    }

    /* 获取所有开启资源红包的商户 */
    public static getOpenresource() {
        return Request({
            url: `/core/api/v1/admin/b/merchant/all/open/resource`,
            method: "get",
            loading: false
        });
    }

    //资源平台 打开/关闭
    public static platformOpen(merchantId: any, data: any) {
        return Request({
            url: `/core/api/v1/admin/b/resource/platform/open/${merchantId}?open=${data.open}`,
            method: "post",
            data
        });
    }

    /* 帐户资金 - 退款确认 */
    public static refundAgree(merchantId: any, data: any) {
        return Request({
            url: `/core/api/v1/admin/b/resource/refund/${merchantId}`,
            method: "post",
            data
        });
    }

}
