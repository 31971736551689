
import {Component, Vue} from 'vue-property-decorator';
import {Foundation} from '@/utils/Foundation';
import API_MerchantList from "@/utils/API/merchant/MerchantList";
import {integer} from "@/utils/validate";

@Component({
    name: 'addMerchant',
})
export default class extends Vue {
    title: string = "编辑";
    form: any = {};
    isLoading: boolean = false;
    rules: any = {
        coName: [{required: true, message: '请输入充值金额', trigger: 'blur'}],
        remark: [{required: true, message: '请输入备注', trigger: 'blur'}],
        status: [{required: true, message: '请选择是否垫付', trigger: 'blur'}]
    }

    show(data: any = {}) {
        (this.$refs.emitRefund as any).show();
        this.$nextTick(() => {
            (this.$refs.addForm as any).clearValidate();
        });
    }

    hide() {
        (this.$refs.emitRefund as any).hide();
    }

    /*表单提交*/
    async submitForm() {
        let form = Foundation.resetParams(this.form);

        (this.$refs.addForm as any).validate(async (valid: any) => {
            if (valid) {
                if (this.isLoading) return;
                this.isLoading = true;
                try {
                    this.form.id ? await API_MerchantList.updateMerchant(form) : await API_MerchantList.addMerchant(form);
                    this.isLoading = false;
                    this.$message.success('操作成功');
                    this.hide();
                    this.$emit('submitForm', form);
                } catch (e) {
                    this.isLoading = false;
                }
            } else {
                return false;
            }
        });
    }

    /*图片上传*/
    uploadImg() {
        (this.$refs.imgChoice as any).show();
    }

    /*选择图片*/
    choiceImg(img: any) {
        this.$set(this.form, "imageUrl", img[0].ossUrl)
    }
}
